import { useAuthContext } from "@/hooks/useAuthContext"; // Hook de autenticação
import { useState } from "react";
import { Skeleton } from "@/shadcn/components/ui/skeleton"; // Importa o componente Skeleton
import { Card, CardContent, CardHeader, CardTitle } from "@/shadcn/components/ui/card";
import { Separator } from "@/shadcn/components/ui/separator";

export default function Solicite() {
  const { user } = useAuthContext(); // Obtém o usuário autenticado
  const [loading, setLoading] = useState(true); // Estado para controlar o carregamento

  // Construindo a URL somente com o parâmetro userId
  const formUrl = user ? `https://form-saas-formbricks-app.c8t4ma.easypanel.host/s/cm2t6dhlx000ocslk226akwkx?userId=${user.uid}` : "";

  const handleIframeLoad = () => {
    setLoading(false); // Desativa o Skeleton assim que o iframe for carregado
  };

  return (
    <div className="flex items-center justify-center min-h-screen relative">
      {/* Skeleton cobrindo toda a tela enquanto o iframe carrega */}
      {loading && (
        <Skeleton className="absolute inset-0 w-full h-full animate-pulse" />
      )}

      {/* Conteúdo principal centralizado */}
      <div className={`${loading ? "opacity-0" : "opacity-100"} transition-opacity duration-500 flex flex-col items-center`}>
        {/* Header com título e descrição */}
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-800">Solicite Seu Projeto</h1>
          <p className="text-gray-600 mt-2">Preencha o formulário para solicitar seu projeto conosco. Estamos animados para trabalhar com você!</p>
        </div>

        {/* Card de conteúdo centralizado */}
        <Card className="w-full max-w-4xl h-[50vh]  shadow-xl bg-white rounded-lg">
          <CardHeader>
            <CardTitle className="text-xl font-semibold text-center">Formulário </CardTitle>
            <Separator className="my-4" />
          </CardHeader>

          <CardContent className="flex flex-col items-center">
            {/* Iframe do formulário */}
            {user && (
              <iframe className="border-2 border-gray-100 rounded-lg "
                src={formUrl} // Usa a URL com o parâmetro userId
                onLoad={handleIframeLoad} // Define o Skeleton como inativo após o carregamento
                loading="lazy" // Carregamento atrasado para otimizar a performance
                style={{
                  position: "relative",
                  width: "100%",
                  height: "270px",
                  visibility: loading ? "hidden" : "visible", // Esconde o iframe até carregar
                  
                }}
                title="Formulário de Solicitação"
              ></iframe>
            )}
          </CardContent>
        </Card>

        {/* Footer com link de suporte */}
        
      </div>
    </div>
  );
}
