import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase/config";
import { useAuthContext } from "./useAuthContext";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();

  const login = async (email, password) => {
    setError(null);
    setIsPending(true);

    try {
      const res = await signInWithEmailAndPassword(auth, email, password);

      // Dispatch login action
      dispatch({ type: "LOGIN", payload: res.user });

      setIsPending(false);
      setError(null);
    } catch (err) {
      // Identifica o erro do Firebase
      if (err.code === "auth/wrong-password") {
        setError("auth/wrong-password");
      } else if (err.code === "auth/user-not-found") {
        setError("auth/user-not-found");
      } else {
        setError("auth/bad-request");
      }
      setIsPending(false);
    }
  };

  return { login, error, isPending };
};
