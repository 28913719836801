import { Button } from "@/shadcn/components/ui/button";
import { Input } from "@/shadcn/components/ui/input";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useLogin } from "../../hooks/useLogin";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/shadcn/hooks/use-toast";

export default function Login() {
  const { login, isPending, error } = useLogin();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validationError, setValidationError] = useState("");
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setValidationError("Todos os campos são obrigatórios.");
      return;
    }

    setValidationError("");
    try {
      await login(email, password);
      toast({
        title: "Login realizado!",
        description: "Bem-vindo de volta à plataforma.",
        variant: "default",
      });
      navigate("/");
    } catch (err) {
      toast({
        title: "Erro no login",
        description: "Verifique suas credenciais e tente novamente.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="h-screen flex flex-col md:flex-row">
      <div className="animate-fadeInUp flex w-full md:w-3/5 md:bg-gradient-to-r from-zinc-900 justify-around items-center">
        <div className="w-full h-full flex items-center justify-center">
          <img 
            src="https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logo_normal__1_-removebg.png?alt=media&token=26cbb328-5470-44e2-a6b2-5d48ccf565ba" 
            alt="Logo" 
            className="w-1/2 md:w-3/4 lg:w-1/2 xl:w-2/3" 
          />
        </div>
      </div>

      <div className="animate-fadeInUp flex w-full md:w-1/2 justify-center items-center bg-white p-4 md:p-0">
        <form className="bg-white w-full max-w-md px-6 md:px-8 py-6 rounded-lg" onSubmit={handleLogin}>
          <h1 className="text-gray-800 font-bold text-2xl mb-1">Olá!</h1>
          <p className="text-sm font-normal text-gray-600 mb-7">Seja bem-vindo novamente</p>

          <div className="flex items-center border-2 py-2 px-3 rounded-2xl mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-envelope-at-fill" viewBox="0 0 16 16">
              <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 9.671V4.697l-5.803 3.546.338.208A4.5 4.5 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671"/>
              <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791"/>
            </svg>
            <Input
              id="email"
              type="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="ml-2 pl-2 w-full text-zinc-700"
              placeholder="Email de acesso"
            />
          </div>

          <div className="flex items-center border-2 py-2 px-3 rounded-2xl mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="bi bi-envelope-at-fill" viewBox="0 0 16 16">
              <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2M2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
            </svg>
            <Input
              id="password"
              type="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="ml-2 pl-2 w-full focus:outline-none focus:ring-0 text-zinc-700 placeholder-gray-400"
              placeholder="Senha"
            />
          </div>

          {validationError && (
            <p className="text-red-500 text-sm">{validationError}</p>
          )}

          <Button
            type="submit"
            size="lg"
            className="block w-full bg-slate-700 mt-4 py-2 rounded-2xl text-white font-semibold mb-2"
            disabled={isPending}
          >
            {isPending ? "Carregando..." : error ? "Senha inválida" : "Entrar na minha conta"}
          </Button>

          <div className="mt-4 text-center">
            <Link to="/esqueceusenha" className="text-indigo-600 hover:underline">
              Esqueceu a senha?
            </Link>
          </div>

          <div className="mt-6 text-center">
            <p className="text-sm text-gray-600">Não tem uma conta?</p>
            <Link to="/signup" className="text-indigo-600 hover:underline">
              Cadastre-se agora
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}