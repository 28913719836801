// stripeService.js
import { loadStripe } from '@stripe/stripe-js';

const ENDPOINTS = {
  CHECKOUT: 'https://createcheckoutsession-irt7ykqbia-uc.a.run.app',
  CANCEL: 'https://cancelapplication-irt7ykqbia-uc.a.run.app'
};

let stripePromise = null;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
  }
  return stripePromise;
};

// Função para criar/atualizar assinatura
export async function createSubscription(userId, numApps, customerId = null, hasActiveSubscription = false) {
  try {
    console.log('Creating/updating subscription:', {
      userId,
      numApps,
      customerId,
      hasActiveSubscription
    });

    const response = await fetch(ENDPOINTS.CHECKOUT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId,
        numApps,
        customerId,
        hasActiveSubscription
      })
    });

    if (!response.ok) {
      const errorData = await response.text();
      console.error('Subscription error response:', errorData);
      throw new Error(`Failed to process subscription: ${errorData}`);
    }

    const data = await response.json();
    console.log('Subscription processed:', data);

    if (data.success) {
      // Atualização bem-sucedida
      return data;
    } else if (data.sessionId) {
      // Nova assinatura - redireciona para checkout
      localStorage.setItem('stripe_session_id', data.sessionId);
      localStorage.setItem('requested_app_limit', numApps.toString());
      return data;
    }

    throw new Error('Invalid response from server');
  } catch (error) {
    console.error('Subscription service error:', error);
    throw error;
  }
}

// Função para cancelar assinatura
export async function cancelSubscription(userId, applicationId) {
  try {
    const response = await fetch(ENDPOINTS.CANCEL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId,
        applicationId
      })
    });

    if (!response.ok) {
      const errorData = await response.text();
      throw new Error(`Failed to cancel subscription: ${errorData}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Cancellation service error:', error);
    throw error;
  }
}

export { getStripe };