import { useState, useEffect } from "react";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { auth, db, timestamp } from "../firebase/config";
import { useAuthContext } from "./useAuthContext";

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();

  const signup = async (email, password, name, phoneNumber) => {
    setError(null);
    setIsPending(true);

    try {
      // Tenta criar o usuário
      const res = await createUserWithEmailAndPassword(auth, email, password);

      if (!res) {
        throw new Error("Não foi possível realizar o cadastro.");
      }

      // Atualiza o perfil do usuário com o nome
      await updateProfile(auth.currentUser, { displayName: name });

      // Cria o documento do usuário no Firestore
      const createdAt = timestamp;
      await setDoc(doc(db, "users", res.user.uid), {
        id: res.user.uid,
        online: true,
        createdAt,
        email: email,
        name: name,
        phoneNumber: phoneNumber, // Incluindo o telefone
      });

      // Dispara a ação de login
      dispatch({ type: "LOGIN", payload: res.user });

      // Atualiza o estado
      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      // Tratamento de erro mais específico com base no código do erro do Firebase
      let errorMessage;
      if (err.code === "auth/email-already-in-use") {
        errorMessage = "Esse e-mail já está em uso. Tente outro.";
      } else if (err.code === "auth/weak-password") {
        errorMessage = "A senha deve ter pelo menos 6 caracteres.";
      } else if (err.code === "auth/invalid-email") {
        errorMessage = "O e-mail informado é inválido.";
      } else {
        errorMessage = "Erro ao tentar criar a conta. Tente novamente.";
      }

      // Exibe a mensagem de erro
      console.error("Erro durante o signup:", err.message);

      if (!isCancelled) {
        setError(errorMessage);
        setIsPending(false);
      }
    }
  };

  useEffect(() => () => setIsCancelled(true), []);

  return { error, isPending, signup };
};
