import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import {
  Card,
  CardHeader,
  CardContent,
  CardTitle,
  CardDescription,
} from "@/shadcn/components/ui/card";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/shadcn/components/ui/select";
import { useApplications } from "@/hooks/useApplications";
import { Alert, AlertDescription } from "@/shadcn/components/ui/alert";

const UsageChart = ({ userId, startDate, endDate }) => {
  const { 
    fetchUserApplications, 
    fetchDailyMetrics,
    loading: hookLoading, 
    error: hookError 
  } = useApplications();
  
  const [metrics, setMetrics] = useState([]);
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState('all');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadApplications = async () => {
      if (!userId) return;
      setLoading(true);
      try {
        const apps = await fetchUserApplications(userId);
        if (apps.length > 0) {
          setApplications(apps);
        }
      } catch (err) {
        setError("Erro ao carregar aplicações: " + err.message);
      } finally {
        setLoading(false);
      }
    };

    loadApplications();
  }, [userId]);

  useEffect(() => {
    const loadMetrics = async () => {
      if (!userId || !startDate || !endDate || !applications.length) {
        return;
      }

      setLoading(true);
      try {
        let metricsData = {};

        // Se selecionou todas as aplicações
        if (selectedApplication === 'all') {
          // Busca métricas para cada aplicação
          for (const app of applications) {
            const appMetrics = await fetchDailyMetrics(
              userId,
              app.id,
              startDate,
              endDate
            );

            // Agrupa os dados por data
            appMetrics.forEach(metric => {
              const date = metric.date;
              if (!metricsData[date]) {
                metricsData[date] = {
                  cpu_avg: 0,
                  ram_avg: 0,
                  count: 0
                };
              }
              metricsData[date].cpu_avg += Number(metric.cpu_avg);
              metricsData[date].ram_avg += Number(metric.ram_avg);
              metricsData[date].count += 1;
            });
          }

          // Calcula a média para cada data
          const formattedMetrics = Object.entries(metricsData).map(([date, data]) => ({
            date: format(new Date(date), "dd/MM"),
            CPU: (data.cpu_avg).toFixed(2),
            Memory: data.ram_avg >= 1000 
              ? `${(data.ram_avg / 1024).toFixed(2)}`
              : `${Math.round(data.ram_avg)}`,
            rawCPU: data.cpu_avg,
            rawMemory: data.ram_avg
          }));

          setMetrics(formattedMetrics.sort((a, b) => new Date(a.date) - new Date(b.date)));
        } else {
          // Busca métricas apenas da aplicação selecionada
          const dailyMetrics = await fetchDailyMetrics(
            userId,
            selectedApplication,
            startDate,
            endDate
          );

          const formattedMetrics = dailyMetrics.map(metric => ({
            date: format(new Date(metric.date), "dd/MM"),
            CPU: Number(metric.cpu_avg).toFixed(2),
            Memory: metric.ram_avg >= 1000 
              ? `${(metric.ram_avg / 1024).toFixed(2)}`
              : `${Math.round(metric.ram_avg)}`,
            rawCPU: metric.cpu_avg,
            rawMemory: metric.ram_avg
          }));

          setMetrics(formattedMetrics);
        }
      } catch (err) {
        setError("Erro ao carregar métricas: " + err.message);
      } finally {
        setLoading(false);
      }
    };

    loadMetrics();
  }, [userId, startDate, endDate, selectedApplication, applications]);

  const calculateAverage = (data, key) => {
    if (!data || data.length === 0) return 0;
    const avg = data.reduce((acc, curr) => acc + curr[`raw${key}`], 0) / data.length;
    if (key === 'CPU') {
      return avg.toFixed(2);
    }
    return avg >= 1000 
      ? `${(avg / 1024).toFixed(2)}GB`
      : `${Math.round(avg)}MB`;
  };

  if (!userId || !startDate || !endDate) return null;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Uso de Recursos</CardTitle>
        <CardDescription>Métricas de CPU e Memória</CardDescription>
      </CardHeader>
      <CardContent>
        {applications.length > 0 ? (
          <div className="mb-6">
            <Select 
              value={selectedApplication} 
              onValueChange={setSelectedApplication}
            >
              <SelectTrigger className="w-[200px]">
                <SelectValue placeholder="Selecione uma aplicação" />
              </SelectTrigger>
              <SelectContent className="bg-slate-200">
                <SelectItem value="all">Todas as aplicações</SelectItem>
                {applications.map(app => (
                  <SelectItem key={app.id} value={app.id}>
                    {app.type} - {app.id}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        ) : !loading && !error && (
          <Alert>
            <AlertDescription>
              Nenhuma aplicação encontrada.
            </AlertDescription>
          </Alert>
        )}

        {(error || hookError) && (
          <Alert variant="destructive" className="mb-4">
            <AlertDescription>
              {error || hookError}
            </AlertDescription>
          </Alert>
        )}

        {(loading || hookLoading) ? (
          <div className="h-[300px] flex items-center justify-center">
            <p className="text-muted-foreground">Carregando dados...</p>
          </div>
        ) : metrics.length > 0 ? (
          <>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={metrics}
                margin={{ top: 20, right: 30, left: 0, bottom: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  label={{ value: "Data", position: "insideBottom", offset: -5 }}
                />
                <YAxis
                  label={{ 
                    value: "Uso", 
                    angle: -90, 
                    position: "insideLeft",
                    offset: 10
                  }}
                />
                <Tooltip 
                  formatter={(value, name) => [
                    `${value}${name === 'Memory' ? 'MB' : ''}`, 
                    name === 'Memory' ? 'Memória' : 'CPU'
                  ]}
                  labelFormatter={(label) => `Data: ${label}`}
                />
                <Legend 
                  formatter={(value) => value === 'Memory' ? 'Memória' : 'CPU'}
                />
                <Line
                  type="monotone"
                  dataKey="CPU"
                  stroke="#8884d8"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  activeDot={{ r: 8 }}
                />
                <Line
                  type="monotone"
                  dataKey="Memory"
                  stroke="#82ca9d"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                />
              </LineChart>
            </ResponsiveContainer>

            <div className="mt-4 space-y-2">
              <p className="text-sm text-muted-foreground">
                Último registro:
                {' '}
                <span className="font-medium">
                  CPU: {metrics[metrics.length - 1].CPU} | 
                  Memória: {metrics[metrics.length - 1].Memory}MB
                </span>
              </p>
              <p className="text-sm text-muted-foreground">
                Média do período:
                {' '}
                <span className="font-medium">
                  CPU: {calculateAverage(metrics, 'CPU')} | 
                  Memória: {calculateAverage(metrics, 'Memory')}
                </span>
              </p>
            </div>
          </>
        ) : (
          <div className="h-[300px] flex items-center justify-center">
            <p className="text-muted-foreground">
              Nenhum dado disponível para o período selecionado
            </p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default UsageChart;