import React, { useEffect, useState } from 'react';
import { 
  Card, 
  CardContent, 
  CardDescription, 
  CardHeader, 
  CardTitle 
} from "@/shadcn/components/ui/card";
import { db } from '../../firebase/config';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { formatDistance } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Skeleton } from "@/shadcn/components/ui/skeleton";

const TransactionHistory = ({ userId }) => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userId) return;

    const transactionsRef = collection(db, 'users', userId, 'transactions');
    const transactionsQuery = query(transactionsRef, orderBy('createdAt', 'desc'));

    const unsubscribe = onSnapshot(
      transactionsQuery,
      (snapshot) => {
        const transactionData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt?.toDate()
        }));
        setTransactions(transactionData);
        setLoading(false);
      },
      (error) => {
        console.error('Erro ao buscar transações:', error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [userId]);

  const formatTransactionType = (type) => {
    switch (type) {
      case 'subscription_created':
        return 'Assinatura Iniciada';
      case 'subscription_updated':
        return 'Assinatura Atualizada';
      case 'subscription_canceled':
        return 'Assinatura Cancelada';
      case 'payment_success':
        return 'Pagamento Realizado';
      case 'payment_failed':
        return 'Pagamento Falhou';
      case 'renewal_success':
        return 'Renovação Realizada';
      case 'renewal_failed':
        return 'Renovação Falhou';
      default:
        return type
          .replace(/_/g, ' ')
          .replace(/\b\w/g, l => l.toUpperCase())
          .replace('Subscription', 'Assinatura')
          .replace('Payment', 'Pagamento')
          .replace('Success', 'Sucesso')
          .replace('Failed', 'Falhou')
          .replace('Renewal', 'Renovação');
    }
  };

  if (loading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Histórico de Transações</CardTitle>
          <CardDescription>Carregando alterações da assinatura...</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="flex justify-between items-center">
                <Skeleton className="h-4 w-32" />
                <Skeleton className="h-4 w-24" />
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    );
  }

  if (transactions.length === 0) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Histórico de Transações</CardTitle>
          <CardDescription>Nenhuma transação encontrada</CardDescription>
        </CardHeader>
        <CardContent>
          <p className="text-sm text-muted-foreground">
            Seu histórico de transações aparecerá aqui após sua primeira assinatura.
          </p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Histórico de Transações</CardTitle>
        <CardDescription>Alterações recentes da assinatura</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {transactions.map((transaction) => (
            <div 
              key={transaction.id} 
              className="flex justify-between items-center border-b pb-2 last:border-0"
            >
              <div>
                <div className="font-medium">
                  {formatTransactionType(transaction.type)}
                </div>
                {transaction.previousAppLimit !== undefined && (
                  <div className="text-sm text-muted-foreground">
                    {transaction.previousAppLimit} → {transaction.newAppLimit} portas de aplicação
                  </div>
                )}
                {transaction.amount && (
                  <div className="text-sm text-muted-foreground">
                    Valor: R$ {(transaction.amount / 100).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </div>
                )}
              </div>
              <div className="text-sm text-muted-foreground">
                {transaction.createdAt ? (
                  formatDistance(transaction.createdAt, new Date(), { 
                    addSuffix: true,
                    locale: ptBR 
                  })
                ) : (
                  'Agora mesmo'
                )}
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default TransactionHistory;