import React, { useRef, useEffect, useState } from "react";
import { icons } from "./IconsData";
import { Button } from "@/shadcn/components/ui/button";
import { Card, CardContent, CardHeader } from "@/shadcn/components/ui/card";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "@/shadcn/components/ui/accordion";
import { Github, CheckCircle, ChevronDown, ChevronUp } from "lucide-react";

const navigation = [
  { name: "Funcionalidades", href: "#funcionalidades" },
  { name: "Instalação", href: "#instalacao" },
  { name: "Ferramentas", href: "#ferramentas" },
  { name: "FAQ", href: "#faq" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Aplicativo() {
  const funcionalidadesRef = useRef(null);
  const ferramentasRef = useRef(null);
  const InstalacaoRef = useRef(null);
  const faqRef = useRef(null);

  const handleScroll = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Remove o observador após a primeira animação
        }
      },
      { threshold: 0.2 } // Ajuste o threshold conforme necessário
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) observer.unobserve(sectionRef.current);
    };
  }, []);

  return (
    <div className="">
      <div className="animate-fadeInUp relative bg-gradient-to-br from-zinc-900 via-zinc-800 to-zinc-700 min-h-screen overflow-hidden rounded-b-3xl">
        <div className="absolute inset-0 bg-gradient-to-t from-zinc-700 via-transparent to-transparent opacity-30"></div>
        <div className="absolute inset-0 bg-gradient-to-b from-zinc-900 via-zinc-800 to-zinc-900 opacity-20 mix-blend-multiply"></div>

        <header className="absolute inset-x-0 top-0 z-50">
          <nav
            aria-label="Global"
            className="flex items-center justify-between p-4 sm:p-6 lg:px-60"
          >
            <div className="flex lg:flex-1">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  alt=""
                  src="https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logo_LE_no_background.png?alt=media&token=1ddf6aef-7131-4aea-beea-901f7e538918"
                  className="h-20 w-auto sm:h-40"
                />
              </a>
            </div>
            <div className="flex lg:hidden">
              <Button
                type="button"
                onClick={() => setMobileMenuOpen(true)}
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-zinc-300"
              >
                <span className="sr-only">Open main menu</span>
              </Button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <Button
                  key={item.name}
                  onClick={() => {
                    if (item.href === "#funcionalidades")
                      handleScroll(funcionalidadesRef);
                    if (item.href === "#ferramentas")
                      handleScroll(ferramentasRef);
                    if (item.href === "#instalacao")
                      handleScroll(InstalacaoRef);
                    if (item.href === "#faq") handleScroll(faqRef);
                  }}
                  className="text-sm sm:text-lg font-semibold text-zinc-200 hover:text-zinc-100"
                >
                  {item.name}
                </Button>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <Button
                onClick={() => (window.location.href = "/signup")}
                className=" text-sm font-semibold text-zinc-200 hover:text-zinc-100"
              >
                Cadastre-se{" "}
                <span className="ml-2" aria-hidden="true">
                  &rarr;
                </span>
              </Button>
              <Button
                onClick={() => (window.location.href = "/login")}
                className="border-2 border-indigo-500 text-sm font-semibold text-zinc-200 hover:text-zinc-100"
              >
                Login
              </Button>
            </div>
          </nav>
        </header>

        <div className="relative isolate px-4 sm:px-6 pt-14 lg:px-8">
          <div
            aria-hidden="true"
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-2xl sm:-top-80"
          ></div>
          <div className="mx-auto max-w-2xl py-16 sm:py-32 lg:py-48">
            <div className="hidden sm:mb-8 sm:flex sm:justify-center ">
              <div className="relative rounded-full p-[2px] border-2 border-indigo-500 to-indigo-700">
                <div className="rounded-full px-3 py-1 text-xs sm:text-sm/6 text-zinc-200 bg-transparent ring-1 ring-transparent ">
                  Simplificamos a maneira de se criar aplicações.
                </div>
              </div>
            </div>
            <div className="text-center animate-fadeIn p-4">
              <div className="mt-5 max-w-2xl text-center mx-auto">
                <h1 className="block font-bold text-zinc-200 text-3xl sm:text-4xl md:text-5xl lg:text-6xl">
                  Instale ferramentas
                  <span className="bg-clip-text bg-gradient-to-t from-indigo-400 to-indigo-600 text-transparent">
                    {" "}
                    OpenSource
                  </span>
                </h1>
                <h1 className="block font-bold text-zinc-200 text-3xl sm:text-4xl md:text-5xl lg:text-6xl">
                  em poucos segundos
                </h1>
              </div>
              <p className="mt-6 sm:mt-8 text-base sm:text-lg font-medium text-zinc-400 sm:text-xl/8">
                Diga adeus para a dificuldade de instalar e hospedar suas
                aplicações usando a I Love Deploy! Fazemos tudo isso por você
                rapidamente.
              </p>
              <div className="mt-8 sm:mt-10 flex items-center justify-center gap-x-4 sm:gap-x-6">
                <Button
                  onClick={() => (window.location.href = "/signup")}
                  class="relative group overflow-hidden px-6 h-12 rounded-full flex space-x-2 items-center bg-gradient-to-r from-indigo-500 to-purple-500 hover:to-purple-600"
                >
                  <span class="relative text-sm text-white">Comece agora</span>
                  <div class="flex items-center -space-x-3 translate-x-3">
                    <div class="w-2.5 h-[1.6px] rounded bg-white origin-left scale-x-0 transition duration-300 group-hover:scale-x-100"></div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 stroke-white -translate-x-2 transition duration-300 group-hover:translate-x-0"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </div>
                </Button>
                <Button
                  onClick={() => handleScroll(funcionalidadesRef)}
                  className="text-xs sm:text-sm font-semibold text-zinc-200 hover:text-zinc-100"
                >
                  Saiba mais{" "}
                  <span className="ml-2" aria-hidden="true">
                    →
                  </span>
                </Button>
              </div>
            </div>
          </div>
          <div
            aria-hidden="true"
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-2xl sm:top-[calc(100%-30rem)]"
          ></div>
        </div>
      </div>

      <div className="">
        <div
          id="funcionalidades"
          ref={funcionalidadesRef}
          className=" flex items-center justify-center p-2 transition-opacity duration-50 transform ${
                 "
        >
          <div class=" max-w-[85rem] w-full px-4 py-10 sm:px-6 lg:px-8 lg:py-14  rounded-2xl mx-auto">
            <div class="mb-8 lg:mb-12">
              <h2
                className=" text-3xl font-semibold text-zinc-800 md:mb-6 lg:mb-8 lg:text-4xl xl:text-5xl transition-opacity duration-100 transform ${
                 
              "
              >
                Recursos que somente a I Love Deploy <br /> tem no mercado
              </h2>
            </div>

            <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
              <div
                ref={sectionRef}
                className="p-6 rounded-xl shadow-md border border-zinc-300 bg-gradient-to-br from-zinc-100 via-zinc-200 to-zinc-100 hover:shadow-lg hover:scale-105 transition duration-150 transform ${
                 
              "
              >
                <div class="relative flex justify-center items-center size-12 bg-zinc-200 rounded-xl border border-zinc-300 shadow-inner p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                    />
                  </svg>
                </div>
                <div class="mt-5">
                  <h3 class="text-lg font-semibold text-zinc-700">
                    Dimensionamento automático
                  </h3>
                  <p class="mt-1 text-zinc-500">
                    À medida que seu serviço precisar de mais e mais demanda,
                    podemos expandir automaticamente os recursos necessários.
                  </p>
                </div>
              </div>
              <div
                ref={sectionRef}
                className="p-6 rounded-xl shadow-md border border-zinc-300 bg-gradient-to-br from-zinc-100 via-zinc-200 to-zinc-100 hover:shadow-lg hover:scale-105 transition duration-150 transform ${
             "
              >
                <div class="relative flex justify-center items-center size-12 bg-zinc-200 rounded-xl border border-zinc-300 shadow-inner p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 9.776c.112-.017.227-.026.344-.026h15.812c.117 0 .232.009.344.026m-16.5 0a2.25 2.25 0 0 0-1.883 2.542l.857 6a2.25 2.25 0 0 0 2.227 1.932H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-1.883-2.542m-16.5 0V6A2.25 2.25 0 0 1 6 3.75h3.879a1.5 1.5 0 0 1 1.06.44l2.122 2.12a1.5 1.5 0 0 0 1.06.44H18A2.25 2.25 0 0 1 20.25 9v.776"
                    />
                  </svg>
                </div>
                <div class="mt-5">
                  <h3 class="text-lg font-semibold text-zinc-700">
                    Multi Ambiente
                  </h3>
                  <p class="mt-1 text-zinc-500">
                    Copie facilmente toda a sua arquitetura em vários ambientes
                    diferentes, testar e crie em ambientes isolados cada um dos
                    seus projetos!
                  </p>
                </div>
              </div>

              <div
                ref={sectionRef}
                className="p-6 rounded-xl shadow-md border border-zinc-300 bg-gradient-to-br from-zinc-100 via-zinc-200 to-zinc-100 hover:shadow-lg hover:scale-105 transition duration-150 transform ${
               
              "
              >
                <div class="relative flex justify-center items-center size-12 bg-zinc-200 rounded-xl border border-zinc-300 shadow-inner p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                    />
                  </svg>
                </div>
                <div class="mt-5">
                  <h3 class="text-lg font-semibold text-zinc-700">
                    Deploy feito por IA
                  </h3>
                  <p class="mt-1 text-zinc-500">
                    Faça deploy de qualquer ferramenta em segundos e tenha o
                    acesso imediato sem se preocupar com servidor e
                    configurações chatas.
                  </p>
                </div>
              </div>

              <div
                ref={sectionRef}
                className="p-6 rounded-xl shadow-md border border-zinc-300 bg-gradient-to-br from-zinc-100 via-zinc-200 to-zinc-100 hover:shadow-lg hover:scale-105 transition duration-150 transform ${
              "
              >
                <div class="relative flex justify-center items-center size-12 bg-zinc-200 rounded-xl border border-zinc-300 shadow-inner p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 17.25v-.228a4.5 4.5 0 0 0-.12-1.03l-2.268-9.64a3.375 3.375 0 0 0-3.285-2.602H7.923a3.375 3.375 0 0 0-3.285 2.602l-2.268 9.64a4.5 4.5 0 0 0-.12 1.03v.228m19.5 0a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3m19.5 0a3 3 0 0 0-3-3H5.25a3 3 0 0 0-3 3m16.5 0h.008v.008h-.008v-.008Zm-3 0h.008v.008h-.008v-.008Z"
                    />
                  </svg>
                </div>
                <div class="mt-5">
                  <h3 class="text-lg font-semibold text-zinc-700">
                    Servidores potentes
                  </h3>
                  <p class="mt-1 text-zinc-500">
                    Todas suas aplicações estarão dentro de um servidor potente,
                    então esqueça a dor de cabeça e tenha velocidade e
                    eficiencia em seus projetos!
                  </p>
                </div>
              </div>

              <div
                ref={sectionRef}
                className="p-6 rounded-xl shadow-md border border-zinc-300 bg-gradient-to-br from-zinc-100 via-zinc-200 to-zinc-100 hover:shadow-lg hover:scale-105 transition duration-150 transform ${
             "
              >
                <div class="relative flex justify-center items-center size-12 bg-zinc-200 rounded-xl border border-zinc-300 shadow-inner p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                    />
                  </svg>
                </div>
                <div class="mt-5">
                  <h3 class="text-lg font-semibold text-zinc-700">
                    Tire duvidas
                  </h3>
                  <p class="mt-1 text-zinc-500">
                    Tenha acesso a uma aba de tutoriais além de chats com IA's
                    projetadas para você consultar detalhes e dúvidas sobre suas
                    aplicações
                  </p>
                </div>
              </div>

              <div
                ref={sectionRef}
                className="p-6 rounded-xl shadow-md border border-zinc-300 bg-gradient-to-br from-zinc-100 via-zinc-200 to-zinc-100 hover:shadow-lg hover:scale-105 transition duration-150 transform relative"
              >
                <span className="absolute top-2 right-2 text-xs font-semibold text-white bg-indigo-500 px-2 py-1 rounded-full">
                  Em breve
                </span>

                <div className="relative flex justify-center items-center size-12 bg-zinc-200 rounded-xl border border-zinc-300 shadow-inner p-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z"
                    />
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                </div>
                <div className="mt-5">
                  <h3 className="text-lg font-semibold text-zinc-700">
                    Variáveis ​​e configurações
                  </h3>
                  <p className="mt-1 text-zinc-500">
                    Gerencie todas as variáveis ​​complexas que seu sistema
                    precisa em um único arquivo de configuração, sem mais
                    arquivos .env confusos!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center bg-white text-gray-900 p-8 mt-40">
        {/* Seção principal do texto (à direita e alinhado à direita) */}
        <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between gap-44 p-6 md:p-8 max-w-7xl mx-auto">
  <div className="lg:w-1/2 lg:pt-8">
    <div className="text-left max-w-xl">
      <h2 className="text-purple-500 text-xl md:text-2xl font-semibold">
        Hospede também nos seus servidores
      </h2>
      <h1 className="text-3xl md:text-4xl font-bold mt-2">
        Tudo isso em menos de 2 minutos.
      </h1>
      <p className="text-gray-600 text-base md:text-lg mt-4">
        Não perca mais seu tempo com configurações complexas e deixe a I Love Deploy fazer tudo pra você.
      </p>
    </div>
    <div className="flex justify-center mt-8">
      <Button 
        onClick={() => window.location.href = "/signup"}
        className="relative group overflow-hidden px-8 h-12 rounded-full flex items-center space-x-2 bg-gradient-to-r from-indigo-500 to-purple-500 hover:to-purple-600 text-white"
      >
        <span className="text-base">Comece agora</span>
        <div className="flex items-center -space-x-3 translate-x-3">
          <div className="w-2.5 h-[1.6px] rounded bg-white origin-left scale-x-0 transition duration-300 group-hover:scale-x-100"></div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 stroke-white -translate-x-2 transition duration-300 group-hover:translate-x-0"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
          </svg>
        </div>
      </Button>
    </div>
  </div>

  <div className="lg:w-1/2">
    <div className="w-full max-w-md mx-auto bg-white rounded-lg shadow-lg p-8 space-y-6">
      <div className="flex items-center space-x-4">
        <div className="bg-gray-100 p-3 rounded-full">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-layers-3"><path d="m12.83 2.18a2 2 0 0 0-1.66 0L2.6 6.08a1 1 0 0 0 0 1.83l8.58 3.91a2 2 0 0 0 1.66 0l8.58-3.9a1 1 0 0 0 0-1.83Z"/><path d="m6.08 9.5-3.5 1.6a1 1 0 0 0 0 1.81l8.6 3.91a2 2 0 0 0 1.65 0l8.58-3.9a1 1 0 0 0 0-1.83l-3.5-1.59"/><path d="m6.08 14.5-3.5 1.6a1 1 0 0 0 0 1.81l8.6 3.91a2 2 0 0 0 1.65 0l8.58-3.9a1 1 0 0 0 0-1.83l-3.5-1.59"/></svg>
        </div>
        <span className="font-medium text-gray-800">Aplicação escolhida</span>
      </div>

      <div className="w-px h-8 bg-gray-200 ml-7"></div>

      <div className="flex items-center space-x-4">
        <div className="bg-gray-100 p-3 rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-indigo-600 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
          </svg>
        </div>
        <span className="font-medium text-gray-800">Deploy sendo realizado...</span>
      </div>

      <div className="w-px h-8 bg-gray-200 ml-7"></div>

      <div className="flex items-center space-x-4">
        <div className="bg-green-100 p-3 rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <span className="font-medium text-green-500">Aplicação ativa!</span>
      </div>
    </div>

    
  </div>
</div>
      </div>
      <div
        id="instalacao"
        ref={InstalacaoRef}
        className=" mx-auto px-4 sm:px-6 lg:px-8 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10 transition-opacity duration-1000 transform"
      >
        <div className=" lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="mt-12 sm:mt-24 md:mt-32 text-lg md:text-xl font-semibold text-indigo-500">
                Deploys rápidos
              </p>
              <h1 className="mt-2 text-3xl sm:text-4xl md:text-5xl font-semibold tracking-tight text-gray-900">
                Veja como é Simples a instalação de ferramentas
              </h1>
            </div>
          </div>
        </div>
        <div className="mt-12 md:mt-24 lg:mt-48 lg:mb-40 p-6 sm:p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            alt=""
            src="https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/video-paginadevenda%2Fgif-pagina.gif?alt=media&token=deee367e-a3ed-411c-ac52-596ce7f926c5"
            className="w-full sm:w-[48rem] max-w-full sm:max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 border-2 border-slate-400"
          />
        </div>
        <div className="mb-12 lg:mb-40 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-full md:max-w-xl text-base sm:text-lg text-gray-700 lg:max-w-lg">
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-10 h-10"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
                    />
                  </svg>
                  <span>
                    <strong className="font-semibold text-gray-900 text-lg md:text-xl">
                      Passo 1.
                    </strong>{" "}
                    Escolha a aplicação open source que você deseja usar
                    conversando com nossa IA.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-12 h-12"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5.25 14.25h13.5m-13.5 0a3 3 0 0 1-3-3m3 3a3 3 0 1 0 0 6h13.5a3 3 0 1 0 0-6m-16.5-3a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3m-19.5 0a4.5 4.5 0 0 1 .9-2.7L5.737 5.1a3.375 3.375 0 0 1 2.7-1.35h7.126c1.062 0 2.062.5 2.7 1.35l2.587 3.45a4.5 4.5 0 0 1 .9 2.7m0 0a3 3 0 0 1-3 3m0 3h.008v.008h-.008v-.008Zm0-6h.008v.008h-.008v-.008Zm-3 6h.008v.008h-.008v-.008Zm0-6h.008v.008h-.008v-.008Z"
                    />
                  </svg>
                  <span>
                    <strong className="font-semibold text-gray-900 text-lg md:text-xl">
                      Passo 2.
                    </strong>{" "}
                    Seu deploy é instantaneamente feito e suas aplicações sempre
                    estarão online com o melhor desempenho.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-12 h-12"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
                    />
                  </svg>
                  <span>
                    <strong className="font-semibold text-gray-900 text-lg md:text-xl">
                      Passo 3.
                    </strong>{" "}
                    Acesse suas aplicações no mesmo lugar, reduza os custos e
                    melhore as velocidades de transmissão.
                  </span>
                </li>
              </ul>
              <h2 className="mt-16 text-lg sm:text-xl md:text-2xl font-bold tracking-tight text-gray-900">
                Alguma dúvida? Sem problemas.
              </h2>
              <p className="mt-6 text-base sm:text-lg">
                Nossa IA é capaz de te ajudar com qualquer assunto relacionado a
                integrações e te entregar um suporte com a melhor qualidade
                possível, tirando qualquer dúvida relacionada a suas aplicações.
              </p>
            </div>
          </div>
        </div>
      </div>

      <section
        section
        id="ferramentas"
        ref={ferramentasRef}
        className="rounded-t-3xl bg-gradient-to-b from-gray-800 to-black text-gray-100 py-16"
      >
        <div className="container mx-auto text-center">
          <h3 className="text-lg text-gray-300 font-bold">
            Por que escolher a I Love Deploy?
          </h3>
          <h2 className="text-3xl md:text-4xl font-bold my-4 text-white">
            +99 ferramentas disponíveis
          </h2>
          <p className="text-gray-300 max-w-xl mx-auto mb-12 text-base md:text-lg">
            Oferecemos um arsenal de ferramentas para diversas necessidades,
            desde bancos de dados até inteligência artificial!
          </p>

          <div className="grid grid-cols-4 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 gap-2 sm:gap-4">
            {icons.map((icon, index) => (
              <Card
                key={index}
                className="flex justify-center items-center border border-gray-700 bg-wh shadow-lg rounded-lg transform hover:scale-105 transition duration-200"
              >
                <CardContent className="flex justify-center">
                  <img
                    src={icon.src}
                    alt={icon.name}
                    className="h-8 sm:h-12 md:h-16 mt-4"
                  />
                </CardContent>
              </Card>
            ))}
          </div>
        </div>

        <div className="flex items-center justify-center min-h-[30vh] text-white mt-16 py-12">
          <div className="text-center space-y-6">
            <div className="flex justify-center mb-4">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logo_LE_no_background.png?alt=media&token=1ddf6aef-7131-4aea-beea-901f7e538918"
                className="h-32 sm:h-40 md:h-52"
              />
            </div>

            <h1 className="text-2xl sm:text-3xl md:text-5xl font-extrabold">
              Faça deploys instantâneos
            </h1>

            <p className="text-sm sm:text-base md:text-lg text-gray-300 max-w-lg mx-auto">
              Deixe de lado a dificuldade de criar e gerenciar aplicações e
              reduza e muito seus custos com a <br /> I Love Deploy.
            </p>

            <Button
              onClick={() => (window.location.href = "/signup")}
              className="relative group overflow-hidden px-8 sm:px-10 h-10 sm:h-12 rounded-full flex space-x-2 items-center bg-gradient-to-r from-indigo-500 to-purple-500 hover:to-purple-600 mx-auto"
            >
              <span className="relative text-sm sm:text-base text-white">
                Comece agora
              </span>
              <div className="flex items-center -space-x-3 translate-x-3">
                <div className="w-2.5 h-[1.6px] rounded bg-white origin-left scale-x-0 transition duration-300 group-hover:scale-x-100"></div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 sm:h-5 sm:w-5 stroke-white -translate-x-2 transition duration-300 group-hover:translate-x-0"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>
            </Button>
          </div>
        </div>
        <div section id="faq" ref={faqRef} className=" mt-40 max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
  <div className="grid md:grid-cols-5 gap-10">
    <div className="md:col-span-2">
      <div className="max-w-xs">
        <h2 className="text-2xl font-bold md:text-4xl md:leading-tight">Perguntas frequentes</h2>
        <p className="mt-1 hidden md:block text-gray-400">Encontre respostas para as dúvidas mais comuns sobre nossa plataforma</p>
      </div>
    </div>

    <div className="md:col-span-3">
      <Accordion type="single" collapsible className="divide-y divide-gray-200">
        <AccordionItem value="item-1">
          <AccordionTrigger className="group w-full py-3 text-lg font-semibold text-left text-gray-300 hover:text-gray-100">
            Como funciona a I Love Deploy?
          </AccordionTrigger>
          <AccordionContent>
            <p className="text-gray-400">A I Love Deploy é uma plataforma que automatiza o processo de instalação e deploy de aplicações open source. Nossa IA identifica suas necessidades e configura tudo automaticamente, eliminando a complexidade técnica e reduzindo o tempo de implementação de horas para minutos.</p>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-2">
          <AccordionTrigger className="group w-full py-3 text-lg font-semibold text-left text-gray-300 hover:text-gray-100">
            Quais são os planos e preços disponíveis?
          </AccordionTrigger>
          <AccordionContent>
            <p className="text-gray-400">Oferecemos um sistema de balanço que se adapta ao seu uso. Desde um uso básico para projetos pessoais até grandes usos empresariais para grandes demandas. Todos incluem suporte técnico e atualizações automáticas. Você pode começar gratuitamente e fazer upgrade conforme suas necessidades.</p>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-3">
          <AccordionTrigger className="group w-full py-3 text-lg font-semibold text-left text-gray-300 hover:text-gray-100">
            Como funciona o suporte técnico?
          </AccordionTrigger>
          <AccordionContent>
            <p className="text-gray-400">Contamos com suporte técnico 24/7 através de nossa IA especializada e equipe técnica. Além disso, oferecemos uma extensa documentação e tutoriais para ajudar em qualquer situação. Nosso tempo médio de resposta é de menos de 1 hora.</p>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="item-4">
          <AccordionTrigger className="group w-full py-3 text-lg font-semibold text-left text-gray-300 hover:text-gray-100">
            Posso migrar aplicações existentes para a plataforma?
          </AccordionTrigger>
          <AccordionContent>
            <p className="text-gray-400">Sim! Nossa plataforma suporta migração de aplicações existentes. Nossa IA auxilia no processo de migração, garantindo uma transição suave e sem downtime. Também oferecemos ferramentas específicas para facilitar a transferência de dados e configurações.</p>
          </AccordionContent>
        </AccordionItem>

        

        <AccordionItem value="item-6">
          <AccordionTrigger className="group w-full py-3 text-lg font-semibold text-left text-gray-300 hover:text-gray-100">
            Quais medidas de segurança vocês utilizam?
          </AccordionTrigger>
          <AccordionContent>
            <p className="text-gray-400">Implementamos as melhores práticas de segurança, incluindo criptografia de ponta a ponta, backups automáticos, monitoramento 24/7 e certificações de segurança atualizadas. Realizamos auditorias regulares e mantemos conformidade com as principais regulamentações de proteção de dados.</p>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  </div>
</div>
<footer className="mt-24 md:mt-48 w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">
  <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6 mb-10">
    <div className="col-span-full hidden lg:col-span-1 lg:block">
      <a className="flex-none font-semibold text-xl focus:outline-none focus:opacity-80" href="#" aria-label="Brand">
        I Love Deploy
      </a>
      <p className="mt-3 text-xs sm:text-sm text-gray-600">© 2024 I Love Deploy.</p>
    </div>
  </div>

  <div className="pt-5 mt-5 border-t border-gray-200 text-center sm:flex sm:justify-between sm:items-center">
    <div className="flex flex-wrap items-center gap-3 text-sm text-gray-600">
      <a href="#" className="hover:text-gray-800">Termos</a>
      <a href="#" className="hover:text-gray-800">Privacidade</a>
    </div>

    <div className="space-x-4 mt-4 sm:mt-0">
      <a href="https://www.linkedin.com/company/i-love-deploy" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-indigo-400 inline-block">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
          <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
        </svg>
      </a>
      <a href="https://www.instagram.com/ilove_deploy" target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-indigo-400 inline-block">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
          <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
        </svg>
      </a>
    </div>
  </div>
</footer>
      </section>
    </div>
  );
}
