import React from 'react'; 
import { useAuthContext } from '../../hooks/useAuthContext'; // Certifique-se de importar o hook correto
import DifyChat from './DifyChat';

const DifyEmbed = () => {
  const { user } = useAuthContext(); // Obtém o usuário autenticado do contexto

  // Monta a URL com o userId (que é o user.uid do Firebase)
  const difyUrl = "https://dify-ssh-app.c8t4ma.easypanel.host/chat/CFEsgonuLSksQm8K?userId=${user.uid}";

  return (
    <div style={styles.container}>
      <DifyChat/>
    </div>
  );
};

// Estilos inline para o iframe e o contêiner
const styles = {
  container: {
    width: '100%',
    maxWidth: '1920px', // Limita a largura máxima para melhorar a aparência em telas grandes
    margin: '0 auto', // Centraliza o iframe na página
    padding: '20px', // Adiciona espaçamento ao redor do iframe
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Sombra para destacar o iframe
    borderRadius: '10px', // Bordas arredondadas para o contêiner
    backgroundColor: '#f9f9f9', // Fundo suave para o contêiner
  },
  iframe: {
    width: '100%',
    height: '1000px', // Altura do iframe
    border: '2px solid #ccc', // Adiciona borda ao iframe
    borderRadius: '10px', // Bordas arredondadas no iframe
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Sombra suave para o iframe
  },
};

export default DifyEmbed;