import { initializeApp } from "firebase/app";
import { initializeFirestore, serverTimestamp } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// Firebase config aqui embaixo
const firebaseConfig = {
  apiKey: "AIzaSyAyh5A796xYxAcbDkGVZKRnDIVifFMmpQc",
  authDomain: "apps-95324.firebaseapp.com",
  projectId: "apps-95324",
  storageBucket: "apps-95324.appspot.com",
  messagingSenderId: "120017879831",
  appId: "1:120017879831:web:af303f67354fedadbe2d52"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize services
const db = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
});
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);

// Timestamp
const timestamp = serverTimestamp();

export { db, auth, storage, timestamp };
