import React, { useState, useEffect } from "react";
import { format, isSameDay, isWithinInterval, subDays } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "@/shadcn/components/ui/popover";
import { Button } from "@/shadcn/components/ui/button";
import { Calendar } from "@/shadcn/components/ui/calendar";
import UsageChart from "./UsageChart";
import { Card, CardHeader, CardContent, CardTitle } from "@/shadcn/components/ui/card";
import { cn } from "@/lib/utils";
import { useAuthContext } from "@/hooks/useAuthContext";


export default function Uso() {
  const { user } = useAuthContext();
  const [dateRange, setDateRange] = useState({
    startDate: subDays(new Date(), 7), // Default to last 7 days
    endDate: new Date()
  });
  const [balance, setBalance] = useState(150);

  // Debug: Monitor date changes
  useEffect(() => {
    console.log("Date Range updated:", {
      startDate: dateRange.startDate?.toISOString(),
      endDate: dateRange.endDate?.toISOString()
    });
  }, [dateRange.startDate, dateRange.endDate]);

  // Ensure dates are at start and end of day respectively
  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      const start = new Date(dateRange.startDate);
      start.setHours(0, 0, 0, 0);
      
      const end = new Date(dateRange.endDate);
      end.setHours(23, 59, 59, 999);
      
      setDateRange({
        startDate: start,
        endDate: end
      });
    }
  }, []);

  const handleDateChange = (selectedDate) => {
    if (!selectedDate) return;

    selectedDate.setHours(0, 0, 0, 0);

    if (!dateRange.startDate || (dateRange.startDate && dateRange.endDate)) {
      // Start new selection
      setDateRange({
        startDate: selectedDate,
        endDate: null
      });
    } else {
      // Complete selection
      if (selectedDate < dateRange.startDate) {
        // If selected date is before start date,
        // swap dates to maintain chronological order
        setDateRange({
          startDate: selectedDate,
          endDate: new Date(dateRange.startDate.setHours(23, 59, 59, 999))
        });
      } else {
        setDateRange({
          startDate: dateRange.startDate,
          endDate: new Date(selectedDate.setHours(23, 59, 59, 999))
        });
      }
    }
  };

  const isSelectedDate = (date) => {
    const { startDate, endDate } = dateRange;
    return startDate && endDate 
      ? isWithinInterval(date, { start: startDate, end: endDate })
      : startDate && isSameDay(date, startDate);
  };

  // Disable future dates
  const disabledDates = { after: new Date() };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">Uso de Recursos</h1>
      
      <div className="mb-6">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className={cn(
                "w-[380px] justify-start text-left font-normal",
                !dateRange.startDate && "text-muted-foreground"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {dateRange.startDate && dateRange.endDate
                ? `${format(dateRange.startDate, "dd/MM/yyyy")} - ${format(dateRange.endDate, "dd/MM/yyyy")}`
                : dateRange.startDate
                ? `${format(dateRange.startDate, "dd/MM/yyyy")} - Selecione a data final`
                : <span>Selecione um período</span>
              }
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={dateRange.endDate ? null : dateRange.startDate}
              onSelect={handleDateChange}
              initialFocus
              disabled={disabledDates}
              className="bg-slate-200 rounded-md border"
              dayClassName={(date) => isSelectedDate(date) 
                ? "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground" 
                : ""
              }
            />
          </PopoverContent>
        </Popover>
      </div>

      {/* Resource usage chart */}
      <div className="mb-6">
        {dateRange.startDate && dateRange.endDate && user && (
          <UsageChart
            key={`${dateRange.startDate.toISOString()}-${dateRange.endDate.toISOString()}`}
            userId={user.uid}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
          />
        )}
      </div>

     
    </div>
  );
}