import React, { useRef } from "react";
import { Avatar, AvatarImage, AvatarFallback } from "@/shadcn/components/ui/avatar";
import getInitials from "@/utils/getInitials";
import { useAuthContext } from "@/hooks/useAuthContext";
import uploadToStorage from "@/utils/uploadToStorage";
import { updateProfile } from "firebase/auth";
import { auth } from "@/firebase/config";
import { Input } from "@/shadcn/components/ui/input";
import { Button } from "@/shadcn/components/ui/button";
import { useFirestore } from "@/hooks/useFirestore";
import { Skeleton } from "@/shadcn/components/ui/skeleton";

export default function Profile() {
  const { user } = useAuthContext();
  const { updateDocument: updateUser } = useFirestore("users");
  const inputRef = useRef();

  const openFileSelector = () => inputRef.current.click();

  const onFileSelected = async (e) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile || !selectedFile.type.includes("image") || selectedFile.size > 1500000) {
      alert("Por favor, selecione uma imagem menor do que 1,5 MB.");
      return;
    }

    const downloadUrl = await uploadToStorage(
      selectedFile,
      `users/${user.uid}`,
      "profilePic"
    );

    try {
      await updateProfile(auth.currentUser, { photoURL: downloadUrl });
      await updateUser(user.uid, { photoURL: downloadUrl });
      await auth.currentUser.reload();
    } catch (error) {
      console.error("Erro ao atualizar o perfil: ", error);
    }
  };

  if (!user) {
    return (
      <div className="flex p-8 bg-white shadow-lg rounded-lg border-2 border-gray-300">
        <div className="flex flex-col items-start space-y-4 mr-8">
          <Skeleton className="h-24 w-24 rounded-full" />
          <Skeleton className="h-10 w-32 rounded-md" />
        </div>
        <div className="flex flex-col justify-center space-y-5 w-full">
          <Skeleton className="h-10 w-full rounded-md" />
          <Skeleton className="h-10 w-full rounded-md" />
        </div>
      </div>
    );
  }

  // Força o recarregamento da imagem adicionando um timestamp à URL
  const photoURLWithTimestamp = `${user.photoURL}?t=${new Date().getTime()}`;

  return (
    <div className="flex flex-col space-y-8">
      <div className="flex p-4 bg-white shadow rounded-lg border border-gray-200">
        {/* Avatar e Botão Alterar Foto */}
        <div className="flex flex-col items-center space-y-2 mr-4">
          <Avatar className="h-20 w-20 shadow-md cursor-pointer" onClick={openFileSelector}>
            <AvatarImage src={photoURLWithTimestamp} className="object-cover" />
            <AvatarFallback className="bg-primary/50 text-xl">
              {getInitials(user.displayName)}
            </AvatarFallback>
          </Avatar>
          <input
            onChange={onFileSelected}
            ref={inputRef}
            accept="image/*"
            type="file"
            className="hidden"
          />
          <Button
            variant="outline"
            size="sm"
            className="text-sm"
            onClick={openFileSelector}
          >
            Alterar Foto
          </Button>
        </div>

        {/* Informações de Perfil */}
        <div className="flex flex-col justify-center space-y-3 w-full">
          <h1 className="font-medium text-lg text-gray-800 mb-4">Meu Perfil</h1>

          {/* Nome Completo */}
          <div>
            <p className="text-xs text-gray-500 mb-1">Nome completo</p>
            <Input
              disabled
              value={user.displayName}
              readOnly
              type="text"
              className="border border-gray-200 rounded-md p-2 w-full shadow-sm bg-gray-50 text-gray-700 text-sm"
            />
          </div>

          {/* Email */}
          <div>
            <p className="text-xs text-gray-500 mb-1">E-mail</p>
            <Input
              disabled
              value={user.email}
              readOnly
              type="email"
              className="border border-gray-200 rounded-md p-2 w-full shadow-sm bg-gray-50 text-gray-700 text-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
