import { useState } from "react";
import { Button } from "@/shadcn/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/shadcn/components/ui/dialog";

export default function PopupExample() {
  const [isOpen, setIsOpen] = useState(true); // Inicializa com o modal aberto

  return (
    <div className="flex justify-center items-center h-screen">
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Os tutoriais ficam prontos em breve!</DialogTitle>
            <DialogDescription>
              No momento não há nada por aqui...
             </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button onClick={() => setIsOpen(false)}>Beleza!</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
