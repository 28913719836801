import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from "@/shadcn/components/ui/card";
import { Button } from "@/shadcn/components/ui/button";
import { XCircle } from "lucide-react";

const Cancel = () => {
  const navigate = useNavigate();

  return (
    <div className="flex min-h-screen items-center justify-center">
      <Card className="w-[400px]">
        <CardContent className="pt-6 text-center">
          <XCircle className="mx-auto h-12 w-12 text-red-500" />
          <h2 className="mt-4 text-2xl font-semibold">Payment Cancelled</h2>
          <p className="mt-2 text-gray-500">
            No charges were made to your card.
          </p>
          <Button 
            className="mt-6 w-full" 
            onClick={() => navigate('/dashboard')}
          >
            Try Again
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default Cancel;