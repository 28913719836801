import { useState } from "react";
import { TextInput } from "@tremor/react";
import { Button } from "@tremor/react";
import { useResetPassword } from "@/hooks/useResetPassword";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const { resetPassword, error, message, isPending } = useResetPassword();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      return;
    }

    // Envia o e-mail de recuperação
    await resetPassword(email);
  };

  return (
    <div className="flex h-screen w-full bg-gradient-to-r from-indigo-700 to-indigo-200 px-20 py-28 items-center justify-center">
      <div className="flex bg-white shadow-lg p-24 rounded-xl border border-gray-300">
        <div className="flex flex-col justify-center w-full">
          <h1 className="text-3xl font-semibold text-gray-800">Recuperar Senha</h1>
          <p className="mt-3 text-lg text-gray-600">Informe seu e-mail para enviar o link de recuperação de senha.</p>

          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                E-mail
              </label>
              <TextInput
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Digite seu e-mail"
                className="mt-2 block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            {/* Exibe mensagem de erro */}
            {error && <p className="text-red-500 text-sm">{error}</p>}

            {/* Exibe mensagem de sucesso */}
            {message && <p className="text-green-500 text-sm">{message}</p>}

            <Button
              size="lg"
              className={`w-full py-3 text-white ${
                isPending ? "bg-gray-400 cursor-not-allowed" : "bg-indigo-600 hover:bg-indigo-700"
              } rounded-md transition duration-300`}
              type="submit"
              disabled={isPending} // Desabilita o botão enquanto está carregando
            >
              {isPending ? "Enviando..." : "Enviar Link de Recuperação"}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
