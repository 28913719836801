// IconsData.js
export const icons = [
  { name: "Appsmith", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2Fappsmith-768x768-1-300x300.webp?alt=media&token=17e399db-f662-4c62-8130-c172f28163a2" },
  { name: "Baserow", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2FBaserow-768x768-1-300x300.webp?alt=media&token=e858fc29-9fe9-4540-877c-d704532bffaf" },
  { name: "EvolutionApi", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2FEvolutionApi-768x768-1-300x300.webp?alt=media&token=694b1bc4-2881-4ebf-bc94-f56e5897ec5b" },
  { name: "Mautic", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2FMautic-768x768-1-300x300.webp?alt=media&token=ce9bf85e-bcc3-4885-a6fc-b1fb8c889c7a" },
  { name: "MinIO", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2FMinIO-768x768-1-300x300.webp?alt=media&token=886b4d25-94ae-4697-9000-ae9bcd5337e5" },
  { name: "N8N", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2FN8N-768x768-1-300x300.webp?alt=media&token=f333257f-7299-493c-8adf-7e1a1d9f3570" },
  { name: "Supabase", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2FSupabase-768x768-1-300x300.webp?alt=media&token=5c669251-faeb-4009-9ba6-98a2f0fec0ac" },
  { name: "Typebot", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2FTypebot-768x768-1-300x300.webp?alt=media&token=721d2457-ed03-43c4-b424-34f528b231c8" },
  { name: "Wordpress", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2FWordpress-768x768-1-300x300.webp?alt=media&token=82ef6842-2100-41d8-a96e-64a96d38610f" },
  { name: "Grafana", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2FGrafana-768x768-1-300x300.webp?alt=media&token=dc151911-4828-47b2-9705-7be7db652062" },
  { name: "MongoDB", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2FMongoDB-768x768-1-300x300.webp?alt=media&token=b8b8f2a4-cb08-4a6b-baca-9b8f937ec9fa" },
  { name: "Qdrant", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2FQdrant-768x768-1-300x300.webp?alt=media&token=b2266479-efe7-4bd9-b716-2dacdd97302e" },
  { name: "RabbitMQ", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2FRabbitMQ-768x768-1-300x300.webp?alt=media&token=6a9f043e-3a61-43eb-8cdb-7df7afe2c157" },
  { name: "Traefik", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2FTraefik-768x768-1-300x300.webp?alt=media&token=4101a637-4f80-4fb4-8001-fcd6a2d7610b" },
  { name: "Deluge", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2Flogo-Dqo7Pn81.png?alt=media&token=1d2d0efe-f138-40ea-a90b-d7a949f547b4" },
  { name: "Botpress", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2Flogo-CPxeYz7Y.png?alt=media&token=809866b3-6d92-42c4-ad2d-db24f757b624" },
  { name: "Cal", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2Flogo-C3dJsH6B.png?alt=media&token=c841aef8-aa5e-426e-b30b-cfec7a6b4d36" },
  { name: "Dify", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2F127165244.png?alt=media&token=974edf63-1719-4aab-a6f6-39efaad9564f" },
  { name: "Formbriks", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2Flogo-BY_rXYQS.svg?alt=media&token=d4825dd3-cad0-4eb2-aea9-dd53b90a9a2d" },
  { name: "Linkstack", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2Flogo-BZkFUsZH.svg?alt=media&token=b04981b1-ae1d-452d-8f16-954a8483421d" },
  { name: "Js", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2Flogo-Dip3lKgZ.png?alt=media&token=6739c589-3ed4-469d-8fd0-5813f2656fbd" },
  { name: "Minio", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2Flogo-BT-KM1cn.png?alt=media&token=9a51080a-26d0-4373-8281-0b01ea210076" },
  { name: "Plane", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2Fdownload.png?alt=media&token=4b79e5ad-1c75-44ce-9e9a-19fbd856fd3e" },
  { name: "Blender", src: "https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logos_apps%2Flogo-C1X3Tnhx.png?alt=media&token=a4ab896d-a7d8-4c1c-b6a6-71652da39faa" },
];
