// Pricing.jsx
import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "@/shadcn/components/ui/card";
import { useAuthContext } from '../../hooks/useAuthContext';

import TransactionHistory from './TransactionHistory';
import BalanceManager from './AppLimitManager';

const Pricing = () => {
  const { user } = useAuthContext();

  if (!user) return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-lg text-gray-600">Usuário não encontrado</div>
    </div>
  );

  return (
    <div className="w-full md:w-2/3 p-6 bg-white text-gray-900 space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl font-semibold">Faturamento</h1>
        
      </div>

      <BalanceManager userId={user.uid} />
    </div>
  );
};

export default Pricing;