import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/shadcn/components/ui/dialog";
import { Button } from "@/shadcn/components/ui/button";
import { ToastProvider } from "@/shadcn/components/ui/toast";
import { Toaster } from "@/shadcn/components/ui/toaster";
import { useToast } from "@/shadcn/hooks/use-toast";
import Projects from "./pages/Projects/Projects";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import Aplicacoes from "./pages/Aplicacoes/Aplicações";
import { useAuthContext } from "./hooks/useAuthContext";
import { useLogout } from "./hooks/useLogout";
import Profile from "./pages/Profile/Profile";
import Aplicativo from "./pages/Software/Aplicativo";
import ForgotPassword from "./pages/Login/ForgotPassword/ForgotPassword";
import Solicite from "./pages/Solicite/Solicite";
import Tutoriais from "./pages/Tutoriais/Tutoriais";
import { ThemeProvider } from "./Providers/ThemeProvider";
import {
  SidebarProvider,
  SidebarTrigger,
} from "@/shadcn/components/ui/sidebar";
import { AppSidebar } from "@/components/app-sidebar";
import Pricing from "./pages/Pricing/Pricing";
import Dashboard from "./pages/Uso/Uso";
import Typebot from "./pages/Type/Type";
import Success from "./pages/Transação/Success";
import Cancel from "./pages/Transação/Cancel";
import ApplicationManager from "./pages/Aplicacoes/ApplicationManager";

function App() {
  const { user, authIsReady } = useAuthContext();
  const { logout } = useLogout();
  const { toast } = useToast();
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (user && !localStorage.getItem(`hasLoggedIn-${user.uid}`)) {
      toast({
        title: "Login realizado!",
        description: "Bem-vindo de volta à plataforma.",
        variant: "default",
      });
      localStorage.setItem(`hasLoggedIn-${user.uid}`, 'true');
    }
  }, [user, toast]);

  const toggleSidebar = () => {
    setSidebarCollapsed((prev) => !prev);
  };

  if (!authIsReady) {
    return null;
  }

  if (user && isMobile) {
    return (
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Ainda não estamos com a versão mobile 😅</DialogTitle>
            <DialogDescription>
              Use seu computador para ter uma melhor experiência ...
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button onClick={logout}>Voltar ao site!</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
      <ToastProvider>
        <Toaster />
        <div className="App">
          <BrowserRouter>
            {user ? (
              <div className="flex min-h-screen">
                <SidebarProvider
                  className={`transition-all duration-300 ml-4 ${
                    isSidebarCollapsed ? "w-[70px]" : "w-[10px]"
                  }`}
                >
                  <AppSidebar />
                  <SidebarTrigger onClick={toggleSidebar} />
                </SidebarProvider>

                <div
                  className={`flex-grow p-4 overflow-auto transition-all duration-300 ${
                    isSidebarCollapsed ? "ml-[70px]" : "ml-[270px]"
                  }`}
                >
                  <Routes>
                    <Route exact path="/" element={<Projects />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/aplicacoes" element={<ApplicationManager />} />
                    <Route path="/solicite" element={<Solicite />} />
                    <Route path="/tutoriais" element={<Tutoriais />} />
                    <Route path="/pricing" element={<Pricing userId={user.uid} />} />
                    <Route path="/uso" element={<Dashboard />} />
                    <Route path="/type" element={<Typebot />} />
                    <Route path="/success" element={<Success />} />
                    <Route path="/cancel" element={<Cancel />} />
                    <Route path="*" element={<Projects />} />
                  </Routes>
                </div>
              </div>
            ) : (
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/esqueceusenha" element={<ForgotPassword />} />
                <Route path="/app" element={<Aplicativo />} />
                <Route path="*" element={<Aplicativo />} />
              </Routes>
            )}
          </BrowserRouter>
        </div>
      </ToastProvider>
    </ThemeProvider>
  );
}

export default App;