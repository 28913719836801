import { useState } from "react";
import { Button } from "@/shadcn/components/ui/button";
import { Input } from "@/shadcn/components/ui/input";
import { Link } from "react-router-dom";
import { useSignup } from "../../hooks/useSignup";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export default function Signup() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [validationError, setValidationError] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { signup, isPending, error } = useSignup();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!fullName || !email || !password || !phoneNumber) {
      setValidationError("Todos os campos são obrigatórios.");
      return;
    }

    let formattedPhoneNumber = phoneNumber.startsWith("+55") ? phoneNumber : `+55${phoneNumber}`;
    const parsedPhoneNumber = parsePhoneNumberFromString(formattedPhoneNumber, "BR");
    if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
      setPhoneError("Número de telefone inválido. Use o formato (xx) xxxxx-xxxx.");
      return;
    }

    setPhoneError("");
    setValidationError("");
    signup(email, password, fullName, formattedPhoneNumber)
  };
  
  return (
    <div className=" h-screen flex flex-col md:flex-row">
      <div className="flex w-full md:w-3/5 md:bg-gradient-to-r from-zinc-900 justify-around items-center">
        <div className="animate-fadeInUp w-full h-full flex items-center justify-center">
          <img 
            src="https://firebasestorage.googleapis.com/v0/b/apps-95324.appspot.com/o/logo_normal__1_-removebg.png?alt=media&token=26cbb328-5470-44e2-a6b2-5d48ccf565ba" 
            alt="Logo" 
            className="w-1/2 md:w-3/4 lg:w-1/2 xl:w-2/3" 
          />
        </div>
      </div>

      <div className="animate-fadeInUp flex w-full md:w-1/2 justify-center items-center bg-white p-4 md:p-0">
        <form className="bg-white w-full max-w-md px-6 md:px-8 py-6 rounded-lg" onSubmit={handleSubmit}>
          <h1 className="text-gray-800 font-bold text-2xl mb-1">Novo por aqui?</h1>
          <p className="text-sm font-normal text-gray-600 mb-7">Crie sua conta agora</p>

          <div className="flex items-center border-2 py-2 px-3 rounded-2xl mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="text-gray-400" viewBox="0 0 16 16">
              <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
            </svg>
            <Input
              id="fullName"
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              className="ml-2 pl-2 w-full focus:outline-none focus:ring-0 text-zinc-700 placeholder-gray-400"
              placeholder="Nome completo"
            />
          </div>

          <div className="flex items-center border-2 py-2 px-3 rounded-2xl mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope-at-fill" viewBox="0 0 16 16">
              <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 9.671V4.697l-5.803 3.546.338.208A4.5 4.5 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671"/>
              <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791"/>
            </svg>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="ml-2 pl-2 w-full focus:outline-none focus:ring-0 text-zinc-700 placeholder-gray-400"
              placeholder="Email"
            />
          </div>

          <div className="flex items-center border-2 py-2 px-3 rounded-2xl mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="bi bi-envelope-at-fill" viewBox="0 0 16 16">
              <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58z" />
            </svg>
            <Input
              id="phoneNumber"
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="ml-2 pl-2 w-full focus:outline-none focus:ring-0 text-zinc-700 placeholder-gray-400"
              placeholder="Número de telefone"
            />
          </div>

          <div className="flex items-center border-2 py-2 px-3 rounded-2xl mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="bi bi-envelope-at-fill" viewBox="0 0 16 16">
              <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2M2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
            </svg>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="ml-2 pl-2 w-full focus:outline-none focus:ring-0 text-zinc-700 placeholder-gray-400"
              placeholder="Senha"
            />
          </div>

          {phoneError && <p className="text-red-500 text-sm">{phoneError}</p>}
          {validationError && <p className="text-red-500 text-sm">{validationError}</p>}
          {error && <p className="text-red-500 text-sm">{error}</p>}

          <Button
            size="lg"
            className="block w-full bg-slate-700 mt-4 py-2 rounded-2xl text-white font-semibold mb-2"
            disabled={isPending}
          >
            {isPending ? "Carregando..." : "Criar conta"}
          </Button>

          <div className="mt-4 text-center">
            <Link to="/login" className="text-indigo-600 hover:underline">
              Já tem uma conta? Entre agora
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
