import { useEffect } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';

const TypebotEmbed = () => {
  const { user } = useAuthContext();

  useEffect(() => {
    if (user) {
      console.log("User ID:", user.uid);
      localStorage.setItem('userId', user.uid);
    } else {
      console.log("User ainda não carregado ou é null");
    }
  }, [user]);

  if (!user) {
    return (
      <div className="flex items-center justify-center  text-gray-500 text-lg">
        Carregando...
      </div>
    );
  }

  const typebotUrl = "https://principal-viewer.ilovedeploy.host/deploy?userId=${user.uid}";
  console.log("Typebot URL:", typebotUrl);

  return (
    <div className="">
      <iframe
        src={typebotUrl}
        style={{ border: 'none', width: '100%', height: '96vh' }}
        title="Typebot"
      ></iframe>
    </div>
  );
};

export default TypebotEmbed;