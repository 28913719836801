import { storage } from "@/firebase/config";
import { uploadBytesResumable, getDownloadURL, ref } from "firebase/storage";

const uploadToStorage = async (file, path, fileName) => {
  const storageRef = ref(
    storage,
    `${path}/${fileName}.${file.type.split("/")[1]}`
  );

  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      console.log(snapshot);
      // Progress handling can go here
    },
    (error) => {
      console.log(error);
      // Handle errors here
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        console.log("Arquivo disponível na URL: ", downloadURL);
        return downloadURL;
      });
    }
  );
};

export default uploadToStorage;