import { useEffect, useState } from 'react';
import { Progress } from "@/shadcn/components/ui/progress";
import { db } from '../firebase/config';
import { doc, onSnapshot } from 'firebase/firestore';
import { useAuthContext } from "../hooks/useAuthContext";
import { Card, CardContent } from "@/shadcn/components/ui/card";
import { Laptop2, Server } from "lucide-react";

const AppLimitProgress = () => {
  const [currentUserData, setCurrentUserData] = useState(null);
  const { user } = useAuthContext();
  const MAX_APPS = 100;

  useEffect(() => {
    if (!user?.uid) return;

    const userRef = doc(db, 'users', user.uid);
    const unsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        setCurrentUserData({ id: doc.id, ...doc.data() });
      }
    });

    return () => unsubscribe();
  }, [user?.uid]);

  const appLimit = currentUserData?.appLimit || 0;
  const progressPercentage = appLimit > 0 ? 100 : 5; // Show 5% when 0 apps, 100% when has apps

  return (
    <Card className="w-full">
      <CardContent className="p-4">
        <div className="flex items-center mb-3">
          <div className="p-1.5 bg-blue-100 rounded-lg dark:bg-blue-900 mr-2">
            <Server className="w-4 h-4 text-blue-600 dark:text-blue-300" />
          </div>
          <div className="flex-1">
            <div className="flex justify-between items-baseline">
              <span className="text-sm font-medium">Portas Disponíveis</span>
              <span className="text-sm font-semibold">{appLimit}</span>
            </div>
            <div className="text-xs text-gray-500">Total permitido: {MAX_APPS}</div>
          </div>
        </div>

        <div className="space-y-1">
          <Progress
            value={progressPercentage}
            className="h-1.5"
            indicatorClassName={appLimit > 0 ? "bg-green-500" : "bg-red-500"}
          />
          <div className="flex justify-between text-xs text-gray-500">
            <span>{appLimit > 0 ? `${appLimit} em uso` : "0.0% utilizado"}</span>
            <span>{MAX_APPS - appLimit} portas restantes</span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default AppLimitProgress;