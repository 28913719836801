import React, { useState, useRef, useEffect } from 'react';
import { SendHorizonal, Loader2, Bot } from 'lucide-react';
import { useAuthContext } from '@/hooks/useAuthContext';
import { Input } from "@/shadcn/components/ui/input";
import { ScrollArea } from "@/shadcn/components/ui/scroll-area";
import { Button } from "@/shadcn/components/ui/button";
import { Avatar, AvatarImage, AvatarFallback } from "@/shadcn/components/ui/avatar";
import ReactMarkdown from 'react-markdown';

const DifyChat = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const { user } = useAuthContext();
  
  const API_KEY = 'app-Xn9H0UfRtXR4xz1QbwYZPv4r';
  const API_URL = 'https://dify-ssh-app.c8t4ma.easypanel.host/v1/chat-messages';

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Função para focar o input
  const focusInput = () => {
    // Pequeno timeout para garantir que o foco aconteça após todas as atualizações de estado
    setTimeout(() => {
      if (inputRef.current) {
        const input = inputRef.current.querySelector('input');
        if (input) {
          input.focus();
        }
      }
    }, 0);
  };

  // Foca o input quando o componente montar
  useEffect(() => {
    focusInput();
  }, []);

  if (!user) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="w-[350px]">
          <p className="text-yellow-700 text-center">Please login to use the chat.</p>
        </div>
      </div>
    );
  }

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;
    
    setError(null);
    const newMessage = {
      role: 'user',
      content: inputMessage
    };

    setMessages(prev => [...prev, newMessage]);
    setInputMessage('');
    setIsLoading(true);

    // Foca o input após enviar
    focusInput();

    try {
      const payload = {
        inputs: {},
        query: inputMessage,
        user: user.uid,
        response_mode: "streaming",
        conversation_id: conversationId,
      };

      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${API_KEY}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        console.error('Response error:', errorData);
        throw new Error(errorData?.message || `HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      let assistantMessage = '';

      setMessages(prev => [...prev, {
        role: 'assistant',
        content: ''
      }]);

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = new TextDecoder().decode(value);
        const lines = chunk.split('\n');
        
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.slice(6));
              
              if (!conversationId && data.conversation_id) {
                setConversationId(data.conversation_id);
              }

              if (data.answer !== undefined) {
                assistantMessage += data.answer;
                setMessages(prev => {
                  const newMessages = [...prev];
                  const lastMessage = newMessages[newMessages.length - 1];
                  if (lastMessage.role === 'assistant') {
                    lastMessage.content = assistantMessage;
                  }
                  return newMessages;
                });
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        }
      }

    } catch (error) {
      console.error('Error sending message:', error);
      setError(error.message || 'Failed to send message. Please try again.');
      setMessages(prev => prev.slice(0, -1));
    } finally {
      setIsLoading(false);
      // Foca o input após receber a resposta
      focusInput();
    }
  };

  const MessageContent = ({ content }) => (
    <ReactMarkdown
      components={{
        p: ({ children }) => <p className="whitespace-pre-wrap">{children}</p>,
        strong: ({ children }) => <span className="font-bold">{children}</span>,
        a: ({ href, children }) => (
          <a 
            href={href} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-blue-600 hover:text-blue-800 underline"
          >
            {children}
          </a>
        ),
      }}
    >
      {content}
    </ReactMarkdown>
  );

  return (
    <div className="fixed top-4 right-2 bottom-2 left-[300px] flex flex-col bg-white rounded-lg border shadow-lg">
      <div className="flex-none p-4 border-b bg-gray-50/80">
        <div className="flex justify-between items-center w-full">
          <h1 className="text-xl font-bold text-gray-800">Deployer</h1>
        </div>
        {error && (
          <div className="mt-2 text-sm text-destructive bg-destructive/10 p-2 rounded">
            {error}
          </div>
        )}
      </div>

      <div className="flex-1 overflow-hidden">
        <ScrollArea className="h-full bg-white">
          <div className="p-6 space-y-6">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex items-end gap-2 ${message.role === 'user' ? 'flex-row-reverse' : 'flex-row'}`}
              >
                {message.role === 'user' ? (
                  <Avatar className="w-8 h-8">
                    <AvatarImage src={user.photoURL} />
                    <AvatarFallback>{user.email?.charAt(0).toUpperCase()}</AvatarFallback>
                  </Avatar>
                ) : (
                  <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center">
                    <Bot className="w-5 h-5 text-primary" />
                  </div>
                )}
                <div
                  className={`max-w-[80%] p-4 rounded-2xl shadow-sm ${
                    message.role === 'user'
                      ? 'bg-primary text-primary-foreground rounded-br-none'
                      : 'bg-gray-100 text-gray-800 rounded-bl-none'
                  }`}
                >
                  <MessageContent content={message.content} />
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="flex items-end gap-2">
                <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center">
                  <Bot className="w-5 h-5 text-primary" />
                </div>
                <div className="bg-gray-100 p-4 rounded-2xl shadow-sm rounded-bl-none">
                  <Loader2 className="w-5 h-5 animate-spin text-gray-600" />
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        </ScrollArea>
      </div>

      <div className="flex-none border-t p-4 bg-gray-50/80">
        <form onSubmit={sendMessage} className="flex gap-3">
          <div ref={inputRef} className="flex-1">
            <Input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Escreva sua mensagem..."
              disabled={isLoading}
              className="rounded-full border-gray-200 focus:border-primary shadow-sm"
            />
          </div>
          <Button 
            type="submit" 
            disabled={isLoading}
            className="rounded-full px-6 shadow-sm"
          >
            <SendHorizonal className="h-5 w-5" />
          </Button>
        </form>
      </div>
    </div>
  );
};

export default DifyChat;