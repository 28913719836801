import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from "@/shadcn/components/ui/card";
import { Button } from "@/shadcn/components/ui/button";
import { CheckCircle } from "lucide-react";

const Success = () => {
  const navigate = useNavigate();

  return (
    <div className="flex min-h-screen items-center justify-center">
      <Card className="w-[400px]">
        <CardContent className="pt-6 text-center">
          <CheckCircle className="mx-auto h-12 w-12 text-green-500" />
          <h2 className="mt-4 text-2xl font-semibold">Payment Successful!</h2>
          <p className="mt-2 text-gray-500">
            Credits have been added to your account.
          </p>
          <Button 
            className="mt-6 w-full" 
            onClick={() => navigate('/dashboard')}
          >
            Back to Dashboard
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default Success;